import React from 'react'
import { Outlet, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { Footer } from 'src/components/Footer'
import { Header } from 'src/components/Header'

const Main = () => {
    return <>
        <Header />
        <Outlet />
        <Footer />
    </>
}

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<Main />}>
            <Route index lazy={() => import('./Home')} />
            <Route path='location' >
                <Route path=':location' lazy={() => import('./ViewConditions')} />
            </Route>
            <Route
                path="*"
                lazy={() => import('./Error')}
            />
        </Route>
    )
)