import React from 'react'
import styled from 'styled-components'


const StyledHeader = styled.header`
    h1 {
        margin: 0 auto;
        cursor: pointer;
        width: fit-content;
        font-size: 2em;
        margin-bottom: 1em;
        text-align: center;
        color: white;
    }
`

export const Header = () => {
    const handleTitleClick = () => {
        window.location.href = '/'
    }

    return (
        <StyledHeader>
            <h1 onClick={handleTitleClick}>Can I Use A Hammock?</h1>
        </StyledHeader>
    )
}