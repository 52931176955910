import React from  'react'
import styled from 'styled-components'

const StyledFooter = styled.footer`
    bottom: -65px;
    height: 65px;
    position: relative;
`

export const Footer =() => {
    return (
        <StyledFooter>
            <hr />
            <a href='https://gitlab.com/ben_goodman/can-i-use-a-hammock' title='Source code (GitLab)' target="_blank" rel="noopener noreferrer">Code (GitLab)</a>
            <p>Uses weather data provided by <a href="https://www.weatherapi.com/" title="Free Weather API" target="_blank" rel="noopener noreferrer">WeatherAPI.com</a></p>
        </StyledFooter>
    )
}
