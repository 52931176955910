import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { router } from './views/router'


export const App = () => {
    return (
        <>
            <RouterProvider  router={router}/>
        </>
    )
}